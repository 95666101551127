<template>
  <div>
    <Spinner v-if="!cmsContentPending" class="mt-5 mb-5" size="medium" line-fg-color="#1f9979" />
    <!-- about section -->
    <section v-else class="about">
      <b-container>
        <b-row>
          <b-col cols="12" lg="7">
            <h1 v-html="$prismic.asHtml(this.about_title)" class="about__title"></h1>
            <p v-html="$prismic.asHtml(this.about_text_1)" class="main-text main-text--bold about__info"></p>
          </b-col>
          <b-col cols="12" lg="5">
            <img v-lazy="this.about_image_1.url" class="about__image img-fluid" :alt="this.about_image_1.alt" />
          </b-col>
        </b-row>
      </b-container>
      <b-container>
        <b-row>
          <b-col cols="12" lg="7">
            <p v-html="$prismic.asHtml(this.about_text_2)" class="main-text"></p>
          </b-col>
          <b-col cols="12" lg="3" offset-lg="2">
            <p v-html="$prismic.asHtml(this.about_text_5)" class="main-text main-text--bolder text-center"></p>
            <b-row class="text-center" v-if="this.social_media.length > 0">
              <b-col v-for="(item, index) in this.social_media" :key="`${index}`" cols="4">
                <a :href="item.social_media_url.url"><img v-lazy="item.social_media_icon.url" class="img-fluid about__icon" :alt="item.social_media_icon.alt" /></a>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
      <b-container>
        <b-row>
          <b-col cols="12" lg="7">
            <h2 v-html="$prismic.asHtml(this.about_subtitle_1)" class="about__subtitle about__subtitle--margin"></h2>
            <p v-html="$prismic.asHtml(this.about_text_3)" class="main-text"></p>
            <div class="about__address" v-html="$prismic.asHtml(this.about_address)"></div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" lg="7">
            <h2 v-html="$prismic.asHtml(this.about_subtitle_2)" class="about__subtitle about__subtitle--margin"></h2>
            <b-row v-for="(item, index) in this.founders_section" :key="`${index}`" class="mb-5">
              <b-col cols="12" sm="3">
                <img v-lazy="item.founder_image.url" class="img-fluid about__profile" :alt="item.founder_image.alt" />
              </b-col>
              <b-col cols="12" sm="9">
                <div class="main-text" v-html="$prismic.asHtml(item.founder_description)"></div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" lg="7">
            <h2 v-html="$prismic.asHtml(this.about_subtitle_3)" class="about__subtitle"></h2>
            <div class="about__partners">
              <div class="about__element" v-for="(item, index) in this.partners_section" :key="`${index}`">
                <img v-lazy="item.partner_logo.url" class="img-fluid about__company" :alt="item.partner_logo.alt" />
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <!-- aside section -->
    <AsideComponent />
  </div>
</template>

<script>
import Spinner from 'vue-simple-spinner'
export default {
  name: 'AboutPage',
  components: {
    Spinner,
    AsideComponent: () => import('@/components/AsideComponent/AsideComponent.vue')
  },
  data: () => {
    return {
      cmsContentPending: false,
      about_title: null,
      about_image_1: null,
      about_text_1: null,
      about_text_2: null,
      about_subtitle_1: null,
      about_text_3: null,
      about_address: null,
      about_subtitle_2: null,
      about_subtitle_3: null,
      partners_section: [],
      about_text_5: null,
      social_media: [],
      founders_section: []
    }
  },
  methods: {
    async getAboutPageContent () {
      await this.$prismic.client.getSingle('aboutpage')
        .then((document) => {
          // about section
          this.about_title = document.data.about_title
          this.about_image_1 = document.data.about_image_1
          this.about_text_1 = document.data.about_text_1
          this.about_text_2 = document.data.about_text_2
          this.about_subtitle_1 = document.data.about_subtitle_1
          this.about_text_3 = document.data.about_text_3
          this.about_address = document.data.about_address
          this.about_subtitle_2 = document.data.about_subtitle_2
          this.about_subtitle_3 = document.data.about_subtitle_3
          this.partners_section = document.data.partners_section
          this.about_text_5 = document.data.about_text_5
          this.social_media = document.data.social_media
          this.founders_section = document.data.founders_section
          // meta title
          window.document.querySelector('head title').textContent = this.$prismic.richTextAsPlain(document.data.meta_title)
          this.cmsContentPending = true
        })
    }
  },
  created () {
    this.getAboutPageContent()
  }
}
</script>

<style lang="scss">
  @import "./AboutPage.scss";
</style>
